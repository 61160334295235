






























































import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import { ChartData } from "chart.js/auto";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import moment from "moment";

@Component({
  components: { Chart, FscSimpleCard, PrintButton, DocumentButton, Hint },
})
export default class ReportLearnChart extends Vue {
  public name = "ReportLearnChart";

  @Prop()
  public data!: any;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterData!: any;

  @Prop()
  public selectedType!: any;

  public chartColors = ["#169FDB", "#793b86", "#dc0c23", "#ea5b1b", "#f9b342"];

  public get value() {
    if (!this.data) return [];
    let arr: any = [];
    switch (this.selectedType.id) {
      case 2:
        this.data.map((value: any) => {
          arr.push({
            y: this.monthGenerator(value.month),
            x: value.successRate,
          });
        });
        break;
      case 3:
        this.data.map((value: any) => {
          arr.push({
            y: this.monthGenerator(value.month),
            x: value.successRate,
          });
        });
        break;
      default:
        this.data.map((value: any) => {
          arr.push({
            y: this.monthGenerator(value.month),
            x: value.passedExams,
          });
        });
    }
    return arr;
  }

  public get chartData(): ChartData {
    return {
      datasets: [
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: this.selectedType ? this.selectedType.name : "",
          data: this.value,
          backgroundColor: this.chartColors,
          borderColor: this.chartColors,
          pointBackgroundColor: this.chartColors,
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };
  }

  public chartOptions = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value: any) {
            return `${value}`;
          },
        },
      },
    },
  };

  public randomColorGenerator(value: any) {
    const colors = value.map((val: any) => {
      return "#" + (Math.random().toString(16) + "0000000").slice(2, 8);
    });
    return colors;
  }

  public monthGenerator(value: any) {
    if (value) {
      return moment()
        .locale("de")
        .month(value - 1)
        .format("MMM");
    }
  }
}
