import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import moment from "moment/moment";

@Component
export default class ReportRequestMixin extends Vue {
  public dailyDataStartPeriod: string | null = null;
  public dailyDataStartPeriodSuccess = false;
  public dailyDataStartPeriodLoading = false;
  public dailyDataStartPeriodError = null;

  public async fetchDailyDataStartPeriod(): Promise<void> {
    this.dailyDataStartPeriodLoading = true;
    this.dailyDataStartPeriodSuccess = false;
    this.dailyDataStartPeriodError = null;

    return await axios
      .get(`/reports/daily-data-start-period `)
      .then((response: AxiosResponse) => {
        this.dailyDataStartPeriod = response.data;
      })
      .catch((error: AxiosError) => {
        this.dailyDataStartPeriodError = error.response?.data;
      })
      .finally(() => {
        this.dailyDataStartPeriodLoading = false;
      });
  }

  public get dailyDataStartPeriodFormat() {
    if (this.dailyDataStartPeriod) {
      return moment(this.dailyDataStartPeriod, "DD.MM.YYYY").add(1, "M").format("DD.MM.YYYY");
    }
    return "";
  }
}
