











































import { Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PropType } from "vue";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import BaseMixin from "@/mixins/BaseMixin";
import { mixins } from "vue-class-component";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    Validation,
    SaveButton,
    AbortButton,
    Datepicker,
    FscSimpleCard,
  },
})
export default class ReportTrainingFilter extends mixins(BaseMixin) {
  public name = "ReportTrainingFilter";

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @Prop({ type: Array as PropType<Array<IBranch>>, default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ type: Array as PropType<Array<IInstructor>>, default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public paymentWorkFlows!: Array<IBasicNamedDTO>;

  @Prop({ type: Boolean, default: () => false })
  public showPaymentWorkflows!: boolean;

  public licenseClassGroupIds: Array<number> | null = null;
  public branchIds: Array<number> | null = null;
  public instructorIds: Array<number> | null = null;
  public startDate: any = null;
  public paymentWorkflow: null | IBasicNamedDTO = null;

  public mounted() {
    let today = new Date().toISOString().slice(0, 10);
    this.startDate = today;
    this.branchIds = null;
    this.licenseClassGroupIds = null;
    this.instructorIds = null;
    this.paymentWorkflow = null;
  }

  private onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const filterInfo = {
      startDate: this.startDate,
      endDate: this.startDate,
      licenseClassGroupIds: this.licenseClassGroupIds,
      branchIds: this.branchIds,
      instructorIds: this.instructorIds,
      paymentWorkflow: this.paymentWorkflow ? this.paymentWorkflow : null,
    };

    this.$emit(
      "on-submit",
      {
        licenseClassGroupIds: this.onLicenseClassGroupIds(),
        branchIds: this.onBranchIds(),
        instructorIds: this.onInstructorIds(),
        startDate: this.startDate,
        endDate: this.startDate,
        paymentWorkflow: this.paymentWorkflow,
      },
      filterInfo
    );
  }

  private onAbort(): void {
    this.$emit("on-close");
  }

  public branchLabel(branch: IBranch): string {
    return `${branch.postalCode} - ${branch.location}`;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      startDate: {
        required,
      },
    };
  }

  public onLicenseClassGroupIds() {
    if (this.licenseClassGroupIds) {
      let ids = this.licenseClassGroupIds.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return [];
  }

  public onBranchIds() {
    if (this.branchIds) {
      let ids = this.branchIds.map((branch: any) => {
        return branch.id;
      });
      return ids;
    }
    return [];
  }

  public onInstructorIds() {
    if (this.instructorIds) {
      let ids = this.instructorIds.map((instructor: any) => {
        return instructor.id;
      });
      return ids;
    }
    return [];
  }

  public reset(): void {
    this.$v.$reset();
    this.licenseClassGroupIds = null;
    this.branchIds = null;
    this.instructorIds = null;
    this.startDate = null;
    this.paymentWorkflow = null;
  }
}
